import React, { useCallback, useState } from "react";

import Questions from "./Questions";
import CurrentStatus from "./CurrentStatus";
import UserContacts from "./UserContacts";
import Location from "./Location";
import UserAuth from "./UserAuth";
import Finish from "./Finish";

const steps: StepType[] = [
  "current-status",
  "user-auth",
  "user-contacts",
  "questions",
  "location",
  "finish",
];

type StepType =
  | "current-status"
  | "user-auth"
  | "user-contacts"
  | "questions"
  | "location"
  | "finish";

interface MainProps {   
  isLite: boolean;
}

export default function MainPage({ isLite }: MainProps) {
  const [step, setStep] = useState<StepType>(steps[0]);

  const nextHandler = useCallback(() => {
    let stepIndex = steps.findIndex((s) => step === s);
    let nextStepIndex = stepIndex + 1;
    if (steps[nextStepIndex]) {
      setStep(steps[nextStepIndex]);
    }
  }, [step]);

  return (
    <div className="p-3 pt-1  flex items-center justify-center">
      <div className="px-4 mb-5">
        <div className="w-80 sm:w-96 overflow-hidden">
          {step == "current-status" && (
            <CurrentStatus finishHandler={nextHandler} />
          )}
          {step == "user-auth" && <UserAuth finishHandler={nextHandler} />}
          {step == "user-contacts" && (
            <UserContacts finishHandler={nextHandler} />
          )}
          {step == "questions" && (
            <Questions isLite={isLite} finishHandler={nextHandler} />
          )}
          {step == "location" && <Location finishHandler={nextHandler} />}
          {step == "finish" && <Finish />}
           {/* <Finish /> */}
        </div>
      </div>
    </div>
  );
}
