import React, { useEffect, useState } from "react";

import axios from "axios";

import moment from "moment";
import { Icon } from "@iconify/react";

import { ICodeBind } from "../../types/CodeBind.type";

const Result: React.FC = () => {
  const [list, setList] = useState([]);
  const [codeBind, setCodeBind] = useState<ICodeBind | null>(null);
  const [version, setVersion] = useState<string>("default");
  const [enabledShowBind, setEnabledShowBind] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get("/settings")
      .then(({ data }: { data: { codeBind: ICodeBind } }) => {
        setCodeBind(data.codeBind);
      });
  }, []);
  useEffect(() => {
    axios
      .get("/test-result", {
        params: {
          version,
        },
      })
      .then((res) => {
        setList(res.data);
      });
  }, [version]);

  const deleteItemHandler = (id: string) => {
    if (!window.confirm("Удалить тест?")) return;
    axios.delete("/test-result", { data: { id } }).then(() => {
      setList((list) => list.filter((i: any) => i?._id !== id));
    });
  };

  const getBindCode = (
    (codeBind: ICodeBind | null, enabledShowBind: boolean) =>
    (code: "P" | "A" | "E" | "I") => {
      if (codeBind == null || !enabledShowBind) return code;
      return codeBind[code];
    }
  )(codeBind, enabledShowBind);

  return (
    <div className="p-6">
      <div className="px-4 mb-5">
        <label className="label cursor-pointer flex justify-start mb-2">
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={enabledShowBind}
            onChange={() => setEnabledShowBind((p) => !p)}
          />
          <span className="label-text ml-2">Включить привязку кода</span>
        </label>

        <div role="tablist" className="tabs tabs-boxed mb-4">
          <a
            role="tab"
            onClick={() => setVersion("default")}
            className={version == "default" ? "tab tab-active" : "tab"}
          >
            Обычная
          </a>
          <a
            role="tab"
            onClick={() => setVersion("2")}
            className={version == "2" ? "tab tab-active" : "tab"}
          >
            Версия 2
          </a>
          <a
            role="tab"
            onClick={() => setVersion("lite")}
            className={version == "lite" ? "tab tab-active" : "tab"}
          >
            Lite
          </a>
        </div>
        {version == "default" &&
          list.map((it) => (
            <ItemCard deleteItemHandler={deleteItemHandler} data={it} />
          ))}
        {version == "2" &&
          list.map((it) => (
            <ItemCard2
              getBindCode={getBindCode}
              deleteItemHandler={deleteItemHandler}
              data={it}
            />
          ))}
        {version == "lite" &&
          list.map((it) => (
            <ItemCard2
              getBindCode={getBindCode}
              deleteItemHandler={deleteItemHandler}
              data={it}
            />
          ))}
      </div>
    </div>
  );
};

const FieldItem = ({ name = "", value = "" }) => {
  return (
    <div>
      <p className="text-secondary-content text-sm">{name}</p>
      <p className="text-accent text-lg font-bold">{value}</p>
    </div>
  );
};

const ItemCard = ({
  data,
  deleteItemHandler,
}: {
  data: any;
  deleteItemHandler: Function;
}) => {
  return (
    <div className="card mb-4 bg-base-100  shadow-xl overflow-hidden p-4">
      <div className=" mx-2 flex justify-end">
        <button
          onClick={() => deleteItemHandler(data._id)}
          className="btn btn-square btn-outline btn-error"
        >
          <Icon fontSize="26px" icon="mdi:trash-outline" />
        </button>
      </div>
      <div>
        <div className="px-4 py-2">
          <div className="grid sm:grid-cols-2 md:grid-cols-4">
            <FieldItem name="Статус" value={getStatusName(data.user.status)} />
            <FieldItem name="Домен" value={data?.domain} />
            <FieldItem name="Email" value={data?.user?.email} />
            <FieldItem name="Имя" value={data?.user?.name} />
            <FieldItem name="Город" value={data?.user?.city} />
            <FieldItem name="Страна" value={data?.user?.country} />
            <FieldItem name="Телефон" value={data?.user?.phone || "-"} />
            <FieldItem
              name="Дата создания"
              value={moment(data?.createdAt).format("HH:mm DD.MM.YYYY")}
            />
          </div>
        </div>
        <p className="text-primary px-4 mb-4">
          P-{data.PAEI.P.value}({data.PAEI.P.percent}%), A-{data.PAEI.A.value}(
          {data.PAEI.A.percent}%), E-{data.PAEI.E.value}({data.PAEI.E.percent}
          %),I -{data.PAEI.I.value}({data.PAEI.I.percent}%)
        </p>
      </div>
      <div className="collapse collapse-arrow bg-base-100 p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Черты</div>

        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Позитив</th>
                  <th>Негатив</th>
                  <th>Результат</th>
                  <th>Позитив PAEI</th>
                  <th>Негатив PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.traits.map((t: any) => {
                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td className="text-success">{t.positiveValue}</td>
                      <td className="text-error">{t.negativeValue}</td>

                      <td className="text-primary">{t.value}</td>
                      <td className="text-success">{t.positiveAdezisCode}</td>
                      <td className="text-error">{t.negativeAdezisCode}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="collapse collapse-arrow  p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Профессии</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Чексумма</th>
                  <th>Процент</th>
                  <th>PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.professions.map((t: any) => {
                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td>{t.checksum}</td>
                      <td className="text-success">{t.percent} %</td>
                      <td>{t.codes.join(" ")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="collapse p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Персонаж</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Персонаж</th>
                  <th>Фильм</th>
                  <th>Чексумма</th>
                  <th>Процент</th>
                </tr>
              </thead>
              <tbody>
                {data.characters.map((t: any) => {
                  return (
                    <tr>
                      <td className="text-primary">{t.character.character}</td>
                      <td>{t.character.movie}</td>
                      <td>{t.checksum}</td>
                      <td className="text-success">{t.percent} %</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <div className="collapse collapse-arrow  p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Блогер</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>Платформа</th>
                  <th>Жанр</th>
                  <th>Чексумма</th>
                  <th>Процент</th>
                </tr>
              </thead>
              <tbody>
                {data.bloggers.map((t: any) => {
                  return (
                    <tr>
                      <td className="text-primary">{t.platform}</td>
                      <td>{t.genre}</td>
                      <td>{t.checksum}</td>
                      <td className="text-success">{t.percent} %</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function isNumber(val: number) {
  return !isNaN(val);
}

const getBackgroundStyle = (status: string, count: number) => {
  if (status) {
    switch (status) {
      case "lie":
        return "red";
      case "truth":
        return "purple";
      case "doubt":
        return "green";
      default:
        return "black";
    }
  }

  if (count >= 0) {
    if (count <= 2) {
      return "red";
    } else if (count <= 3) {
      return "orange";
    } else if (count > 3) {
      return "green";
    } else {
      return "black";
    }
  }
};

const ItemCard2 = ({
  data,
  deleteItemHandler,
  getBindCode,
}: {
  data: any;
  deleteItemHandler: Function;
  getBindCode: Function;
}) => {
  let order = ["truth", "doubt", "lie"];

  let paei = Object.entries(data.PAEI);

  // .sort((aar: any, bar: any) => {
  //   let a = aar[1];
  //   let b = bar[1];
  //   if (order.indexOf(a.status) !== order.indexOf(b.status)) {
  //     return order.indexOf(a.status) - order.indexOf(b.status);
  //   }
  //   return a.value - b.value;
  // });
  return (
    <div className="card mb-4 bg-base-100  shadow-xl overflow-hidden p-4">
      <div className=" mx-2 flex justify-end">
        <button
          onClick={() => deleteItemHandler(data._id)}
          className="btn btn-square btn-outline btn-error"
        >
          <Icon fontSize="26px" icon="mdi:trash-outline" />
        </button>
      </div>
      <div>
        <div className="px-4 py-2">
          <div className="grid sm:grid-cols-2 md:grid-cols-4">
            <FieldItem name="Пол" value={getSexName(data.user.sex)} />
            <FieldItem name="Статус" value={getStatusName(data.user.status)} />
            <FieldItem name="Домен" value={data?.domain} />
            <FieldItem name="Email" value={data?.user?.email} />
            <FieldItem name="Имя" value={data?.user?.name} />
            <FieldItem name="Город" value={data?.user?.city} />
            <FieldItem name="Страна" value={data?.user?.country} />
            <FieldItem name="Телефон" value={data?.user?.phone || "-"} />
            <FieldItem
              name="Дата создания"
              value={moment(data?.createdAt).format("HH:mm DD.MM.YYYY")}
            />
          </div>
        </div>
        <p className="text-primary px-4 mb-4 ">
          {paei.map(([key, obj]) => {
            return <AdezisInfo key={key} code={getBindCode(key)} data={obj} />;
          })}
          {/* <span
            style={{
              backgroundColor: getBackgroundStyle(data.PAEI.P.status),
              color: "white",
              padding: "5px",
            }}
          >
            P-{data.PAEI.P.value} ({data.PAEI.P.count}
            {data.PAEI?.P?.count > 0 &&
              `-${data.PAEI?.P?.positive}-${data.PAEI?.P?.negative}`}
            ) {data.PAEI?.P?.crucial ? `K-${data.PAEI?.P?.crucial}` : ""}
          </span>
          ,
          <span
            style={{
              backgroundColor: getBackgroundStyle(data.PAEI.A.status),
              color: "white",
              padding: "5px",
            }}
          >
            A-{data.PAEI.A.value}({data.PAEI.A.count}
            {data.PAEI?.A?.count > 0 &&
              `-${data.PAEI?.A?.positive}-${data.PAEI?.A?.negative}`}
            ){data.PAEI?.A?.crucial ? `K-${data.PAEI?.A?.crucial}` : ""}
          </span>
          ,{" "}
          <span
            style={{
              backgroundColor: getBackgroundStyle(data.PAEI.E.status),
              color: "white",
              padding: "5px",
            }}
          >
            E-{data.PAEI.E.value}({data.PAEI.E.count}
            {data.PAEI?.E?.count > 0 &&
              `-${data.PAEI?.E?.positive}-${data.PAEI?.E?.negative}`}
            ){data.PAEI?.E?.crucial ? `K-${data.PAEI?.E?.crucial}` : ""}
          </span>
          ,{" "}
          <span
            style={{
              backgroundColor: getBackgroundStyle(data.PAEI.I.status),
              color: "white",
              padding: "5px",
            }}
          >
            I -{data.PAEI.I.value}({data.PAEI.I.count}
            {data.PAEI?.I?.count > 0 &&
              `-${data.PAEI?.I?.positive}-${data.PAEI?.I?.negative}`}
            ){data.PAEI?.I?.crucial ? `K-${data.PAEI?.I?.crucial}` : ""}
          </span> */}
        </p>

        {data.name && (
          <p className="text-accent text-lg font-bold">{data.name}</p>
        )}
      </div>
      <div className="collapse collapse-arrow bg-base-100 p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Черты</div>

        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Коэфициент</th>
                  <th>Позитив</th>
                  <th>Негатив</th>
                  <th>Результат</th>
                  <th>Позитив PAEI</th>
                  <th>Негатив PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.traits.map((t: any) => {
                  let exist =
                    isNumber(t.positiveValue) && isNumber(t.negativeValue);
                  let isPositive = t.positiveValue >= t.negativeValue;

                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td className="text-success">{t.coeff}</td>
                      <td className="text-success">{t.positiveValue}</td>
                      <td className="text-error">{t.negativeValue}</td>
                      {exist ? (
                        <td
                          className={isPositive ? "text-success" : "text-error"}
                        >
                          {isPositive ? t.value : -t.value}
                        </td>
                      ) : (
                        <td className="text-success">{t.value}</td>
                      )}

                      <td className="text-success">
                        {getBindCode(t.positiveAdezisCode)}
                      </td>
                      <td className="text-error">
                        {getBindCode(t.negativeAdezisCode)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* <thead>
                <tr>
                  <th>Название</th>
                  <th>Коэфициент</th>
                  <th>Результат</th>
                  <th>PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.traits.map((t: any) => {
                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td className="text-success">{t.coeff}</td>
                      <td className="text-error">{t.value}</td>
                      <td className="text-error">{t.code}</td>
                    </tr>
                  );
                })}
              </tbody> */}
            </table>
          </div>
        </div>
      </div>
      <div className="collapse collapse-arrow  p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Профессии</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>Название</th>

                  <th>PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.professions.map((t: any) => {
                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td>{t.codes.join(" ")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="collapse p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Персонаж</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Персонаж</th>
                  <th>Фильм</th>
                  <th>PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.characters.map((t: any) => {
                  return (
                    <tr>
                      <td className="text-primary">{t.character.character}</td>
                      <td>{t.character.movie}</td>
                      <td className="text-success">
                        {t.character.codes.join("")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      {/* <div className="collapse collapse-arrow  p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Блогер</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Платформа</th>
                  <th>Жанр</th>
                  <th>Чексумма</th>
                  <th>Процент</th>
                </tr>
              </thead>
              <tbody>
                {data.bloggers.map((t: any) => {
                  return (
                    <tr>
                      <td className="text-primary">{t.blogger.platform}</td>
                      <td>{t.blogger.genre}</td>
                      <td>{t.checksum}</td>
                      <td className="text-success">{t.percent} %</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
};

function AdezisInfo({ code, data }: { code: string; data: any }) {
  return (
    <span
      style={{
        backgroundColor: getBackgroundStyle(data.status, data.count),
        color: "white",
        padding: "5px",
        marginLeft: "8px",
      }}
    >
      {code} -{data.value}({data.count}
      {typeof data?.positive == "number" &&
        `-${data.positive}-${data.negative}`}
      )
      {typeof data.crucial == "number"
        ? `K(${data.crucial}-${getFormatValue(
            data.positiveCrucial
          )}-${getFormatValue(data.negativeCrucial)})`
        : ""}
    </span>
  );
}

const getFormatValue = (val: any) => {
  if (typeof val == "number") {
    return val;
  } else {
    return "";
  }
};

function getSexName(s: string) {
  switch (s) {
    case "male":
      return "Мужчина";
    case "female":
      return "Женщина";
  }
}

function getStatusName(s: string) {
  switch (s) {
    case "student":
      return "Студент";
    case "search-work":
      return "Ищет работу";
    case "work":
      return "Работает,но ищет новую работу";
  }
}

export default Result;
