import React, { useEffect, useState } from "react";
import axios from "axios";

import { Icon } from "@iconify/react";
import { toast } from "react-toastify";

import { ICodeBind } from "../../types/CodeBind.type";

type LangType = "rus" | "geo" | "eng";

const langs: LangType[] = ["rus", "geo", "eng"];

function Settings() {
  const [traits, setTraits] = useState([]);
  const [lang, setLang] = useState<LangType>(langs[0]);

  useEffect(() => {
    axios.get("/traits").then((res) => {
      setTraits(res.data);
    });
  }, []);

  const traitUnionList = unionMoodTraits(traits);

  return (
    <div>
      <div className="p-6">
        <div className="px-4 mb-5">
          <div className="flex justify-between">
            <p className="text-2xl">Настройки</p>
            <div role="tablist" className="tabs tabs-boxed">
              {langs.map((l) => {
                return (
                  <a
                    role="tab"
                    onClick={() => setLang(l)}
                    className={l == lang ? "tab tab-active" : "tab"}
                  >
                    {l.toUpperCase()}
                  </a>
                );
              })}
            </div>
          </div>
          <ChangeCodeBind />
          <QuestionMode initialMode="" />
          <Questions lang={lang} />

          <p className="text-xl mb-4">Черты</p>
          <div>
            {traitUnionList.map((t: any) => {
              let key = t[0]?._id;
              return (
                <TraitItem
                  key={key}
                  lang={lang}
                  positive={t[0]}
                  negative={t[1]}
                  initialLite={t[0].isLite || t[1].isLite}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function TraitItem({
  positive: positiveData,
  negative: negativeData,
  lang,
  initialLite,
}: {
  positive: any;
  negative: any;
  lang: string;
  initialLite: boolean;
}) {
  const [positive, setPositive] = useState(positiveData);
  const [negative, setNegative] = useState(negativeData);
  const [positiveIsCrucial, setPositiveIsCrucial] = useState(
    positiveData.isCrucial
  );
  const [negativeIsCrucial, setNegativeIsCrucial] = useState(
    negativeData.isCrucial
  );
  const [lite, setLite] = useState(initialLite);

  const fetchHandler = (data: any) => {
    axios.put("/traits", { ...data }).then(() => {
      toast.success("Черта измененна!");
    });
  };

  const saveHandler = (mood: string) => {
    if (mood == "positive") {
      fetchHandler(positive);
    } else if (mood == "negative") {
      fetchHandler(negative);
    }
  };

  const liteChange = () => {
    setLite(!lite);
    axios.put("/traits/lite", { _id: positive._id, isLite: !lite }).then(() => {
      toast.success("Lite измененна!");
    });
  };

  const changeValue = (value: string, mood: string) => {
    if (mood == "positive") {
      setPositive((prev: any) => Object.assign({}, prev, { [lang]: value }));
    } else if (mood == "negative") {
      setNegative((prev: any) => Object.assign({}, prev, { [lang]: value }));
    }
  };

  const adezisChange = (e: any, mood: string) => {
    const value = e.target.value.toUpperCase(); // Приведение к верхнему регистру
    const regex = /^[PAEI]*$/; // Регулярное выражение для допустимых символов

    // Проверка, соответствует ли введённое значение маске
    if (regex.test(value)) {
      if (mood == "positive") {
        setPositive((prev: any) =>
          Object.assign({}, prev, { adezisCode: value })
        );
      } else if (mood == "negative") {
        setNegative((prev: any) =>
          Object.assign({}, prev, { adezisCode: value })
        );
      }
    }
  };

  let positiveName = positiveData?.rus;
  let negativeName = negativeData?.rus;

  const crucialChange = (mood: string) => {
    let queryObj = {};
    // let currentCrucial
    let newIsCrucial: boolean;
    if (mood == "positive") {
      newIsCrucial = !positiveIsCrucial;
      queryObj = {
        _id: positive._id,
        isCrucial: newIsCrucial,
      };
    } else if (mood == "negative") {
      newIsCrucial = !negativeIsCrucial;
      queryObj = {
        _id: negative._id,
        isCrucial: newIsCrucial,
      };
    }
    // { _id: positive._id, isLite: !lite }
    axios.put("/traits", queryObj).then(() => {
      if (mood == "positive") {
        setPositiveIsCrucial(newIsCrucial);
      } else if (mood == "negative") {
        setNegativeIsCrucial(newIsCrucial);
      }
      toast.success("Ключевое значение изменено!");
    });
  };

  return (
    <div className="card mb-2 flex flex-row items-center bg-base-100  shadow-xl overflow-hidden px-4 py-2">
      <div>
        <label className="label cursor-pointer flex flex-col">
          <span className="label-text">Lite</span>
          <input
            type="checkbox"
            className="toggle toggle-sm toggle-primary"
            checked={lite}
            onChange={liteChange}
          />
        </label>
      </div>
      <div className="grid flex-1 sm:grid-cols-1 md:grid-cols-2">
        <div className="flex items-end m-2">
          <div className="flex-1 w-max">
            <div className="label">
              <span className="label-text">{positiveName}</span>
            </div>
            <input
              type="text"
              value={positive[lang] || ""}
              onChange={(e) => changeValue(e.target.value, "positive")}
              className="input w-full input-sm input-bordered"
            />
          </div>

          <div className="ml-3">
            <div className="label">
              <span className="label-text">Код</span>
            </div>
            <input
              type="text"
              value={positive.adezisCode}
              onChange={(e) => adezisChange(e, "positive")}
              className="input w-20  input-sm input-bordered"
            />
          </div>
          <label className="label cursor-pointer flex flex-col ml-3">
            <span className="label-text">Ключе.</span>
            <input
              type="checkbox"
              className="toggle toggle-sm toggle-primary"
              checked={positiveIsCrucial}
              onChange={() => crucialChange("positive")}
            />
          </label>

          <button
            onClick={() => saveHandler("positive")}
            className="btn  ml-3 btn-square btn-sm btn-outline"
          >
            <Icon fontSize="26px" icon="material-symbols:save-outline" />
          </button>
        </div>
        <div className="flex items-end m-2">
          <div className="flex-1">
            <div className="label">
              <span className="label-text">{negativeName}</span>
            </div>
            <input
              type="text"
              value={negative[lang]}
              onChange={(e) => changeValue(e.target.value, "negative")}
              className="input w-full input-sm  input-bordered"
            />
          </div>

          <div className="ml-3">
            <div className="label">
              <span className="label-text">Код</span>
            </div>
            <input
              type="text"
              value={negative.adezisCode}
              onChange={(e) => adezisChange(e, "negative")}
              className="input w-20  input-sm input-bordered"
            />
          </div>
          <label className="label cursor-pointer flex flex-col ml-3">
            <span className="label-text">Ключе.</span>
            <input
              type="checkbox"
              className="toggle toggle-sm toggle-primary"
              checked={negativeIsCrucial}
              onChange={() => crucialChange("negative")}
            />
          </label>

          <button
            onClick={() => saveHandler("negative")}
            className="btn  ml-3 btn-square btn-sm btn-outline"
          >
            <Icon fontSize="26px" icon="material-symbols:save-outline" />
          </button>
        </div>
      </div>
    </div>
  );
}

interface QuestionInterface {
  negativeENG: string;
  negativeGEO: string;
  negativeRUS: string;
  positiveENG: string;
  positiveGEO: string;
  positiveRUS: string;
}

type MoodType = "positive" | "negative";
type UpperLangType = "ENG" | "GEO" | "RUS";
type QuestionKey = `${MoodType}${UpperLangType}`;

function Questions({ lang }: { lang: LangType }) {
  const [question, setQuestion] = useState<null | QuestionInterface>(null);

  useEffect(() => {
    axios.get("/question").then(({ data }: { data: QuestionInterface }) => {
      setQuestion(data);
    });
  }, []);

  const saveHandler = () => {
    if (typeof question == "object" && question !== null) {
      axios.put("/question", { ...question }).then(() => {
        toast.success("Вопросы измененны!");
      });
    }
  };

  const changeValue = (value: string, mood: MoodType) => {
    const key: QuestionKey = `${mood}${lang.toUpperCase()}` as QuestionKey; // Явное указание типа индекса
    setQuestion((prev) => (prev ? { ...prev, [key]: value } : prev));
  };

  if (!question) return null;

  const positiveKey: QuestionKey =
    `positive${lang.toUpperCase()}` as QuestionKey;
  const negativeKey: QuestionKey =
    `negative${lang.toUpperCase()}` as QuestionKey;

  const positiveValue = question[positiveKey];
  const negativeValue = question[negativeKey];
  return (
    <div className="mt-4 mb-6">
      <p className="text-xl">Вопросы</p>
      <div className="grid sm:grid-cols-1 md:grid-cols-2">
        <div className="pr-2">
          <div className="label">
            <span className="label-text">Негатив</span>
          </div>
          <input
            type="text"
            value={negativeValue}
            onChange={(e) => changeValue(e.target.value, "negative")}
            className="input w-full flex-1 input-sm flex-1 input-bordered"
          />
        </div>
        <div className="pl-2">
          <div className="label">
            <span className="label-text">Позитив</span>
          </div>
          <input
            type="text"
            value={positiveValue}
            onChange={(e) => changeValue(e.target.value, "positive")}
            className="input w-full flex-1 input-sm flex-1 input-bordered"
          />
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={saveHandler}
          className="btn  ml-3 btn-square btn-sm btn-outline"
        >
          <Icon fontSize="26px" icon="material-symbols:save-outline" />
        </button>
      </div>
    </div>
  );
}

function unionMoodTraits(traits: any) {
  let result: any = [];
  let positiveTraits = traits.filter((t: any) => t.mood == "positive");

  positiveTraits.forEach((pt: any) => {
    let negativeTrait = traits.find(
      (t: any) => t.base == pt.base && t.mood == "negative"
    );
    result = [...result, [pt, negativeTrait]];
  });

  return result;
}

const QuestionMode = ({ initialMode }: { initialMode: string }) => {
  const [mode, setMode] = useState(initialMode);

  useEffect(() => {
    axios
      .get("/settings")
      .then(({ data }: { data: { questionMode: string } }) => {
        setMode(data.questionMode);
      });
  }, []);

  const changeMode = (value: string) => {
    setMode(value);
    axios.put("/settings", { questionMode: value }).then(() => {
      toast.success("Режим вопросов изменен!");
    });
  };

  return (
    <div className="mb-6">
      <div className="label">
        <span className="text-xl">Режим вопросов</span>
      </div>
      <select
        className="select select-primary w-full max-w-xs"
        value={mode}
        onChange={(e) => changeMode(e.target.value)}
      >
        <option value="2">Версия 2</option>
        <option value="lite">Lite</option>
      </select>
    </div>
  );
};

const ChangeCodeBind = ({}: {}) => {
  const [codeBind, setCodeBind] = useState<ICodeBind | null>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get("/settings")
      .then(({ data }: { data: { codeBind: ICodeBind } }) => {
        setCodeBind(data.codeBind);
      });
  }, []);

  if (!codeBind) return null;

  const codesOrder: string[] = ["P", "A", "E", "I"];

  const codeBindArray = Object.entries(codeBind);

  codeBindArray.sort((a, b) => {
    let codeA: string = a[0];
    let codeB: string = b[0];
    return (
      codesOrder.findIndex((code) => code == codeA) -
      codesOrder.findIndex((code) => code == codeB)
    );
  });

  const changeCode = (code: string, value: string) => {
    value = value[0]?.toUpperCase() || "";
    setCodeBind((prev: ICodeBind | null) => {
      if (prev == null) return null;
      return { ...prev, [code]: value };
    });
  };

  const saveHandler = () => {
    setIsSubmit(true);
    axios
      .put("/settings", { codeBind: codeBind })
      .then(() => {
        toast.success("Привязка измененна!");
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return (
    <div className="mb-6">
      <div className="label">
        <span className="text-xl">Привязка кода</span>
      </div>
      <div className="flex">
        {codeBindArray.map(([code, value]) => {
          return (
            <div key={code} className="mx-2 flex-1">
              <label className="">
                <div className="label">
                  <span className="label-text">{code}</span>
                </div>
                <input
                  type="text"
                  value={value}
                  onChange={(e) => changeCode(code, e.target.value)}
                  className="input w-full  input-sm flex-1 input-bordered"
                />
              </label>
            </div>
          );
        })}
      </div>
      <div className=" mt-5 flex justify-end">
        <button
          onClick={saveHandler}
          disabled={isSubmit}
          className="btn btn-primary btn-outline"
        >
          Сохранить
        </button>
      </div>
    </div>
  );
};

export default Settings;
