import axios from "axios";
import API from "./API";


axios.defaults.baseURL = API.API;

// axios.interceptors.request.use(function (config) {
//   const token = localStorage.getItem("access_token");
//   if (token) {
//     config.headers["token"] = `Bearer ${token}`;
//   }
//   return config;
// });
// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     let message = error?.response?.data?.message;
//     let statusCode = error?.response?.data?.status;
//     let status = error?.response?.status;

//     if (message && !statusCode) {
//       toast.error(message);
//     }

//     // if(status == 403){
//     //   useAuth().logout();
//     // }

//     return Promise.reject(error);
//   }
// );
