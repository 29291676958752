import { useState, useEffect } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";

import { useUser } from "../../context/userContext";
import { getVersion } from "../../utils/getVersion";

interface QuestionInterface {
  negativeENG: string;
  negativeGEO: string;
  negativeRUS: string;
  positiveENG: string;
  positiveGEO: string;
  positiveRUS: string;
}

interface TraitInterface {
  mood: "positive" | "negative";
  name: string;
  code: string;
  base: string;
  geo: string;
  eng: string;

  rus: string;
  _id: string;
  adezisCode: string;
}

interface TraitMoodInterface {
  rus: string;
  geo: string;
  eng: string;
  hide: boolean;
  code: string;
  score: number;
  _id: string;
  adezisCode: string;
}

const formattedMapTrait = () => {
  return (t: TraitInterface): TraitMoodInterface => {
    return {
      _id: t._id,
      rus: t.rus,
      geo: t.geo,
      eng: t.eng,
      code: t.code,
      score: 0,
      hide: false,
      adezisCode: t.adezisCode,
    };
  };
};

type MoodType = "positive" | "negative";
type UpperLangType = "ENG" | "GEO" | "RUS";
type LangType = "eng" | "geo" | "rus";

type QuestionKey = `${MoodType}${UpperLangType}`;

const Questions: React.FC<{
  isLite: boolean;
  finishHandler: Function;
}> = ({ isLite, finishHandler: pageFinishHandler }) => {
  const [question, setQuestion] = useState<QuestionInterface | null>();
  const [negativeTraits, setNegativeTraits] = useState<TraitMoodInterface[]>(
    []
  );
  const [negativePercent, setNegativePercent] = useState<number>(0);
  const [positivePercent, setPositivePercent] = useState<number>(0);

  const [positiveTraits, setPositiveTraits] = useState<TraitMoodInterface[]>(
    []
  );
  const [currentCategory, setCurrentCategory] = useState<
    "negative" | "positive"
  >("negative");

  const user = useUser();

  useEffect(() => {
    // const version = getVersion();
    axios.get("/question").then(({ data }) => {
      setQuestion(data);
    });
    axios
      .get("/traits/client", {})
      .then(({ data }: { data: TraitInterface[] }) => {
        let negativeTraits = data
          .filter((t) => t.mood === "negative")
          .map(formattedMapTrait());
        let positiveTraits = data
          .filter((t) => t.mood === "positive")
          .map(formattedMapTrait());
        setNegativeTraits(negativeTraits);
        setPositiveTraits(positiveTraits);
      });
  }, []);

  const finishHandler = (
    positiveTraits: TraitMoodInterface[],
    negativeTraits: TraitMoodInterface[]
  ) => {
    let allTraits = [...positiveTraits, ...negativeTraits];

    allTraits.sort((a, b) => (a.score > b.score ? -1 : 1));

    // console.log(allTraits, "allTraits");

    let result: { _id: string; value: number }[] = allTraits.map((t) => {
      return {
        _id: t._id,
        value: t.score,
      };
    });

    let message = "";
    allTraits.forEach((r) => {
      message += `${r.rus} ${r.score}\n`;
    });
    user.updateUser("test", result);
    // console.log(message);
    pageFinishHandler();
  };

  const percent = Math.floor((negativePercent + positivePercent) / 2);

  const { t, i18n } = useTranslation();

  let lang = i18n.language as LangType;

  if (!question) return null;

  const positiveKey: QuestionKey =
    `positive${lang.toUpperCase()}` as QuestionKey;
  const negativeKey: QuestionKey =
    `negative${lang.toUpperCase()}` as QuestionKey;

  const positiveQuestion = question[positiveKey];
  const negativeQuestion = question[negativeKey];

  return (
    <>
      <style>
        {`
          .custom-progress::-webkit-progress-value {
            border-radius: 0; /* Убираем скругление у заполненной части */
          }
          .custom-progress::-moz-progress-bar {
            border-radius: 0; /* Убираем скругление у заполненной части для Firefox */
          }
        `}
      </style>
      {/* <div className="card-body mb-0 pb-0 relative">
        <p className="text-center  font-bold text-xl mt-2 font-extralight mb-3">
          {t("question.completedProgressPercent")} {percent}%
        </p>
      </div> */}
      <div className="fixed bottom-0 left-0 right-0 h-9">
        <progress
          className=" progress border-b-0 progress-primary rounded-none h-full border-solid border bg-white border-base-content custom-progress"
          value={percent}
          max="100"
        ></progress>
        <span className="absolute inset-0 flex items-center justify-center font-semibold text-base-content">
          {`${percent}%`}
        </span>
      </div>
      <div className="mt-6">
        {negativeTraits.length && currentCategory == "negative" && (
          <MoodQuestion
            isLite={isLite}
            // showAdezisCode
            lang={lang}
            setPercent={setNegativePercent}
            question={negativeQuestion}
            initTairs={negativeTraits}
            setFinish={(traits: TraitMoodInterface[]) => {
              renderResult(traits);
              setNegativeTraits(traits);
              setCurrentCategory("positive");
            }}
          />
        )}
        {negativeTraits.length && currentCategory == "positive" && (
          <MoodQuestion
            isLite={isLite}
            // showAdezisCode
            lang={lang}
            setPercent={setPositivePercent}
            question={positiveQuestion}
            initTairs={positiveTraits}
            setFinish={(traits: TraitMoodInterface[]) => {
              setPositiveTraits(traits);
              renderResult(traits);
              finishHandler(traits, negativeTraits);
            }}
          />
        )}
      </div>
    </>
  );
};

function shuffleArray(array: TraitMoodInterface[]): TraitMoodInterface[] {
  let arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

const filterHideCallback = (t: TraitMoodInterface) => {
  return !t.hide;
};

function calculatePercentage(x: number, max: number): number {
  return 100 - (x / max) * 100;
}

const MoodQuestion: React.FC<{
  initTairs: TraitMoodInterface[];
  question: string;
  setFinish: Function;
  setPercent: Function;
  lang: "rus" | "geo" | "eng";
  showAdezisCode?: boolean;
  isLite: boolean;
}> = ({
  isLite,
  initTairs,
  question,
  setFinish,
  setPercent,
  lang,
  showAdezisCode = false,
}) => {
  const [traits, setTraits] = useState<TraitMoodInterface[]>(initTairs);
  const [cycleCount, setCycleCount] = useState<number>(0);
  const [answers, setAnswers] = useState<
    {
      rus: string;
      geo: string;
      eng: string;
      _id: string;
      adezisCode: string;
    }[]
  >([]);

  useEffect(() => {
    nextRound();
  }, [traits]);

  const selectHandler = (_id: string) => {
    let newTraits = [...traits];
    answers.forEach((a) => {
      let index = newTraits.findIndex((t) => t._id === a._id);
      // console.debug(newTraits[index]);
      if (a._id == _id) {
        newTraits[index] = {
          ...newTraits[index],
          hide: true,
        };
      } else {
        // console.log(cycleCount, "current cycleCount");
        let maxScore = getCycleLimit(cycleCount);
        let newScore = newTraits[index].score + 1;
        if (isLite) {
          newScore = newScore > maxScore ? maxScore : newScore;
        }
        newTraits[index] = {
          ...newTraits[index],
          score: newScore,
        };
      }
    });
    setTraits(newTraits);
  };

  const nextRound = () => {
    let list = [...traits];

    // console.log(list, "list");
    // А можно когда вопросы , варианты подбирать максимально с разными буквами чтоб человеку было легче сливать ответы?
    // максимально разные в идеале 4 разныйх и 1 повторная

    list = list.filter(filterHideCallback);
    // если не выбраных осталось меньше 5
    // if (list.length <= 5) {
    if (list.length < 5) {
      let resultTraits = [...traits];
      setPercent(100);
      setFinish(resultTraits);
      return;
      //add score and fibinish
    } else {
      // сортируем рандомно
      list = shuffleArray(list);
      //сортируем с наимегьшими очками
      list.sort((a, b) => a.score - b.score);

      let slice = sortByPaei(list);
      // устанавливем
      setCycleCount(cycleCount + 1);
      setAnswers(slice);
    }
  };

  // let percentage = calculatePercentage(
  //   traits.filter((t) => !t.hide).length - 4,
  //   traits.length
  // );

  let percentage = calculatePercentage(
    traits.filter((t) => !t.hide).length - 4,
    traits.length - 4
  );

  const sortByPaei = (list: TraitMoodInterface[]) => {
    let arr = [...list];
    let PAEI = {
      P: arr
        .filter((p) => p.adezisCode == "P")
        .sort((a, b) => a.score - b.score),
      A: arr
        .filter((p) => p.adezisCode == "A")
        .sort((a, b) => a.score - b.score),
      E: arr
        .filter((p) => p.adezisCode == "E")
        .sort((a, b) => a.score - b.score),
      I: arr
        .filter((p) => p.adezisCode == "I")
        .sort((a, b) => a.score - b.score),
    };

    let result: TraitMoodInterface[] = [];

    for (let [key, value] of Object.entries(PAEI)) {
      if (value.length) {
        result.push(value[0]);
      }
    }

    let notInResult = arr.filter((it) => {
      let inResult = result.some((r) => r._id == it._id);
      return !inResult;
    });
    notInResult.sort((a, b) => a.score - b.score);

    let addedCount = 5 - result.length;

    if (addedCount) {
      result = [...result, ...notInResult.slice(0, addedCount)];
    }

    result.sort(() => (Math.random() > 0.5 ? 1 : -1));

    // console.log(result, "result");
    return result;
  };

  useEffect(() => {
    setPercent(percentage);
  }, [percentage]);

  // console.log(renderResult(traits));

  return (
    <div>
      <div className="card-body pt-2">
        <p className="text-xl font-bold text-center">{question}</p>
        <div className="mt-6">
          {answers.map((a) => {
            let { text, subtext } = getTextAndSubtext(a[lang] || "");

            return (
              <button
                key={a._id}
                onClick={() => selectHandler(a._id)}
                className={`btn  flex flex-col btn-outline btn-primary btn-block w-full mb-3 btn-large text-lg py-3 h-auto`}
              >
                <span className="inline">{text}</span>
                {!!subtext ? (
                  <span className="text-sm text-base-content font-extralight">
                    {`(${subtext})`}
                  </span>
                ) : (
                  ""
                )}
                {/* {showAdezisCode ? `(${a.adezisCode})` : ""} */}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

function getTextAndSubtext(text: string) {
  // Получаем текст вне скобок
  let outsideText = text.replace(/\(.*?\)/g, "").trim();

  // Получаем текст внутри скобок
  let insideText: any = text.match(/\((.*?)\)/);

  if (insideText) {
    insideText = insideText.map((item: any) =>
      item.replace(/[()]/g, "").trim()
    );
    insideText = insideText[0];
    // console.log("Тексты внутри скобок:", insideText);
  }

  return {
    text: outsideText,
    subtext: insideText || "",
  };
}

function getCycleLimit(cycleCount: number) {
  let maxScore = 0;
  if (cycleCount >= 0 && cycleCount <= 3) {
    maxScore = 1;
  } else if (cycleCount > 3 && cycleCount <= 5) {
    maxScore = 2;
  } else if (cycleCount > 5 && cycleCount <= 7) {
    maxScore = 3;
  } else if (cycleCount > 7 && cycleCount <= 9) {
    maxScore = 4;
  } else if (cycleCount > 9) {
    maxScore = 5;
  }
  return maxScore;
}
function renderResult(list: TraitMoodInterface[]) {
  let arr = [...list];

  let message = "";
  arr.sort((a, b) => b.score - a.score);

  arr.forEach((r) => {
    message += `${r.rus} ${r.score}\n`;
  });
  console.log(message);
}

export default Questions;
