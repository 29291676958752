interface PropsTypes {
  label?: string;
  errorMessage?: string;
  placeholder?: string;
  type?: "text" | "email" | "tel";
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  containerClassName?: string;
}

const CustomInput: React.FC<PropsTypes> = ({
  label,
  errorMessage,
  placeholder,
  type = "text",
  value,
  onChange,
  onBlur,
  containerClassName = "",
  name,
}) => {
  const isError = !!errorMessage;

  return (
    <label className={`form-control  w-full max-w ${containerClassName}`}>
      {label && (
        <div className="label pb-1">
          <span className="label-text text-base font-bold">{label}</span>
        </div>
      )}
      <input
        type={type}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        className={`input input-lg  input-bordered w-full ${
          isError ? "input-error" : ""
        }`}
      />
      {isError && (
        <div className="label pt-1">
          <span className="text-xs  label-text-alt text-error">
            {errorMessage}
          </span>
        </div>
      )}
    </label>
  );
};

export { CustomInput };
