const isDevMode = process.env.NODE_ENV === "development";

const SERVER = isDevMode
  ? "http://localhost:3000"
  : "https://api.thejobgate.com";

const config = {
  API: SERVER,
  SERVER,
};

export default config;
